@import "src/theme";

.checkout-page {
  width: 100%;

  &_content {
    @include FlexRow(flex-start, flex-start, 3rem, 0);
    position: relative;
    width: 100%;

    @include LessThanMidLaptop {
      flex-direction: column-reverse;
    }
  }

  .item-section {
    position: relative;
    flex: 1 1 auto;
    width: 100%;
  }

  .contact-section {
    flex: 1 0 20%;
    min-width: 400px;
    border: 1px solid $light-shade-darker;

    .contact-forms {
      border: none;

      form {
        gap: 1rem;
      }
    }

    @include LessThanLaptop {
      .contact-forms {
        padding: 2em;

        form {
          width: 100%;
        }
      }
    }

    @include LessThanTablet {
      .contact-forms {
        padding: 1rem;
      }
    }
  }

  .cart-items {
    @include FlexColumn(flex-start, flex-start, 1em, 0);
  }

  .empty {
    @include FlexColumn(center, center, 2em, 1em);

    &-message {
      font-size: 2em;
      font-weight: 600;
      color: $dark;
    }
  }

}

