@import 'variables';

@mixin FlexRow($justify-content: flex-start, $align-item: center, $gap: 1rem, $padding: 0.5rem) {
	display: flex;
	flex-flow: row nowrap;
	align-items: $align-item;
	justify-content: $justify-content;
	padding: $padding;
	gap: $gap;
}

@mixin FlexColumn($justify-content: flex-start, $align-item: flex-start, $gap: 1rem, $padding: 0.5rem) {
	@include FlexRow($justify-content: $justify-content, $align-item: $align-item, $gap: $gap, $padding: $padding);

	flex-flow: column nowrap;
}

@mixin CustomScrollBar() {
	&::-webkit-scrollbar {
		width: 12px; /* Width of the vertical scrollbar */
		height: 12px; /* Height of the horizontal scrollbar */
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px; /* Rounded corners */
		background: $light-shade; /* Light gray track */
	}

	&::-webkit-scrollbar-thumb {
		border: 3px solid $light-shade; /* Adds padding-like effect */
		border-radius: 10px; /* Rounded corners for the thumb */
		background-color: RGBA($light-shade-darker-rgb, 0.5); /* Light gray thumb */
	}

	&::-webkit-scrollbar-thumb:hover {
		background-color: $light-shade-darker; /* Slightly darker on hover */
	}
}


@function RGBA($color: #ffffff, $opacity: 0.1) {
	@return rgba(red($color), green($color), blue($color), $opacity);
}


@mixin MobileView {
	@media screen and (width < $mobileBreakpoint) {
		@content;
	}
}

@mixin TabletView {
	@media screen and (width >= $mobileBreakpoint) and (width < $tabletBreakpoint) {
		@content;
	}
}

@mixin LaptopView {
	@media screen and (width >= $tabletBreakpoint) and (width < $laptopBreakpoint) {
		@content;
	}
}

@mixin LessThanDesktop {
	@media screen and (width < $laptopBreakpoint) {
		@content;
	}
}

@mixin LessThanLaptop {
	@media screen and (width < $laptopBreakpoint) {
		@content;
	}
}

@mixin LessThanTablet {
	@media screen and (width < $mobileBreakpoint) {
		@content;
	}
}

@mixin LessThanMidLaptop {
	@media screen and (width < $midLaptopBreakpoint) {
		@content;
	}
}

@mixin MoreThanMobile {
	@media screen and (width >= $mobileBreakpoint) {
		@content;
	}
}

@mixin MoreThanTablet {
	@media screen and (width >= $tabletBreakpoint) {
		@content;
	}
}

@mixin MoreThanMidLaptop {
	@media screen and (width >= $midLaptopBreakpoint) {
		@content;
	}
}

@mixin MoreThanLaptop {
	@media screen and (width >= $laptopBreakpoint) {
		@content;
	}
}
