@import "src/theme";

.model-card {
  @include FlexColumn(flex-start, flex-start, 1rem, 1rem);
  width: 100%;
  height: fit-content;
  margin: 1rem 0;
  border: 1px solid $light-shade-darker;

  &_header, &_footer {
    @include FlexRow(space-between, center, 0, 0);
    width: 100%;
  }

  &_title {
    font-size: 24px;
    font-weight: 500;
  }

  &_price {
    font-size: 20px;
    font-weight: 600;
    padding: 0.5rem;
  }

  &_content {
    @include FlexColumn(flex-start, flex-start, 0.5rem, 0);
    font-size: 16px;
    width: 100%;
  }

  .model-data {
    &_line {
      @include FlexRow(flex-start, center, 0.5rem, 0);
    }

    &_label {
      flex: 0 1 auto;
      color: gray;
    }

    &_value {
      font-weight: 600;
      flex: 1 0 auto;
    }
  }
}
